import React from "react";
import { IMG_URl } from "../../common/img_url";

function MenuElement({ element, name, props }) {
  return (
    <div
      className="relative w-full h-36 rounded-lg overflow-hidden"
      onClick={() => props.onClickMenu(element.id)}
    >
      <div
        className="w-full h-full bg-cover bg-center"
        style={{ backgroundImage: `url(${IMG_URl}${element.image})` }}
      ></div>

      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div
        className={`absolute bottom-2 ${
          name === "name_en" ? "left-2" : "right-2"
        }  text-white text-lg font-bold`}
      >
        {element[name]}
      </div>
    </div>
  );
}

export default MenuElement;
