import React, { useEffect, useState } from "react";
import BottomSheet from "../ButtomSheet/BottomSheet";
import { useTranslation } from "react-i18next";
import orderServices from "../../api-service/order-service";
import { toast } from "react-toastify";

function OptSend(props) {
  const { t } = useTranslation();
  const [otp, setOtp] = useState("");
  const [resendTimer, setResendTimer] = useState(60);
  useEffect(() => {
    const timer = setInterval(() => {
      setResendTimer((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleOtpChange = (e) => setOtp(e.target.value);
  const handleResend = () => {
    if (resendTimer === 0) {
      setResendTimer(60);
    }
  };

  const sendOtp = () => {
    orderServices
      .sendOtp({
        phone: props.phone,
        otp_code: otp,
        name: props.name,
        branch_id: props.branch_id,
      })
      .then((res) => {
        localStorage.setItem(
          "tokenOrder" + props.branch_id,
          res.data.data.token
        );
        orderServices
          .storeOrder({
            token: res.data.data.token,
            items: props.cartItems,
            note: props.note,
            table_id: 1,
          })
          .then((res) => {
            toast.success(
              `${t("gloabl.orderSuccess")} # ${res.data.data.order_id}`
            );
          })
          .catch((err) => toast.error(t("gloabl.orderError")));
        setOtp("");
        localStorage.removeItem("cart" + props.branch_id);
        props.loadCart();
        props.setPhone("");
        props.setName("");
        props.setNote("");
        props.setOpenOptSheet(false);
      })
      .catch((err) => {
        // console.log(err);
        setOtp("");
        props.setOpenOptSheet(false);
        if (err.status === 422) {
          toast.error(t("gloabl.Invalid"));
        }
      });
  };
  return (
    <BottomSheet
      toggleOpen={(value) => props.setOpenOptSheet(value)}
      title={t("gloabl.otp")}
      open={props.openOptSheet}
    >
      <div className="flex  gap-4 flex-col items-center justify-center p-6">
        <h2 className="text-xl font-semibold ">
          {t("gloabl.verification")} {props.phone}.
        </h2>
        <input
          type="text"
          value={otp}
          onChange={handleOtpChange}
          maxLength="6"
          placeholder="Enter OTP"
          className="p-3 border border-gray-300 rounded-lg text-center text-xl focus:outline-none focus:ring-2 focus:ring-primary mb-4"
        />

        <button
          disabled={otp.length < 6}
          onClick={sendOtp}
          className={`px-6 py-2 rounded-lg font-semibold text-white ${
            otp.length < 6
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-primary text-onPrimary hover:bg-primary-dark transition"
          }`}
        >
          {" "}
          {t("gloabl.send")}
        </button>
        <button
          onClick={handleResend}
          disabled={resendTimer > 0}
          className={`px-6 py-2 rounded-lg font-semibold text-white ${
            resendTimer > 0
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-primary text-onPrimary hover:bg-primary-dark transition"
          }`}
        >
          {resendTimer > 0
            ? `${t("gloabl.resend")} ${t("gloabl.otp")} ${t(
                "gloabl.in"
              )} ${resendTimer}${t("gloabl.s")} `
            : `${t("gloabl.resend")} ${t("gloabl.otp")}`}
        </button>
      </div>
    </BottomSheet>
  );
}

export default OptSend;
