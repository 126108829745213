import { axiosInstance } from "../axios-instance";

const orderServices = {
requestOtp: (payload) => axiosInstance().post(`sendOtp`, payload),
sendOtp: (payload) => axiosInstance().post(`verifyOtp`, payload),
storeOrder: (payload) => axiosInstance().post(`storeOrder`, payload, {
    headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${payload.token}`,
    },
}),
 
};

export default orderServices;
