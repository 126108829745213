import { useState } from "react";
import { IMG_URl } from "../../../common/img_url";
import { Trash } from "lucide-react";
import Iterator from "../../utils/Iterator";
import { useTranslation } from "react-i18next";
import { indexStore } from "../../../store/indexStore";
import Visibility from "../../utils/Visibility";
import { useNavigate, useParams } from "react-router-dom";

const CartItem = ({ element, handleRemoveItem, handleUpdateQuantity }) => {
  const [quantity, setQuantity] = useState(element.amount || 1);
  const { t } = useTranslation();
  const { name } = indexStore();
  const calculateTotalPrice = () => {
    let totalPrice = element.price;
    if (element.option) {
      element.option.forEach((opt) => {
        if (opt.subOption) {
          totalPrice += opt.subOption.price;
        }
      });
    }
    return totalPrice * quantity;
  };

  // دوال لتحديث الكمية
  const handleIncrement = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    handleUpdateQuantity(element, newQuantity);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      handleUpdateQuantity(element, newQuantity);
    }
  };
  const renderOptions = (e, i) => {
    return (
      <div className="text-sm text-onBackground">
        <p key={i}>
          {e.option[name]}: {e.subOption[name]}
          <Visibility visible={e.subOption.price > 0}>
            (+{e.subOption.price} {name == "name_en" ? "AED" : "دإ"})
          </Visibility>
        </p>
      </div>
    );
  };
  const navigate = useNavigate();
  const params = useParams();

  const navegateToProduct = (id) => {
    navigate(
      `/${params.domain}/${params.branch_id}/${element.category_id}/${id}`
    );
  };

  return (
    <div className="flex flex-col sm:flex-row  gap-5 p-4 bg-background rounded-xl shadow-md">
      <div className="flex items-center gap-4 flex-grow">
        <img
          src={`${IMG_URl}${element.image}`}
          alt="img"
          className="object-cover rounded-xl w-20 h-20 sm:w-24 sm:h-24"
          onClick={() => navegateToProduct(element.id)}
        />

        <div className="flex flex-col gap-1 w-full">
          <h3
            className="text-lg font-bold cursor-pointer"
            onClick={() => navegateToProduct(element.id)}
          >
            {element[name]}
          </h3>
          <div className="text-sm text-onBackground ">
            <Iterator
              data={element.option}
              render={(e, i) => renderOptions(e, i)}
            />
          </div>

          <div className="flex items-center text-onBackground gap-2 mt-1 text-sm font-semibold">
            <p>{quantity}</p>
            <span>x</span>
            <p>{(calculateTotalPrice() / quantity).toFixed(2)}</p>
            <span>=</span>
            <p>
              {calculateTotalPrice().toFixed(2)}{" "}
              {name == "name_en" ? "AED" : "دإ"}
            </p>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-2 justify-between">
        <div className="flex items-center gap-3 sm:gap-5">
          <button
            onClick={handleIncrement}
            className="bg-primary text-onPrimary px-3 py-1 rounded-lg text-lg font-semibold"
          >
            +
          </button>
          <span className="text-lg font-semibold">{quantity}</span>
          <button
            onClick={handleDecrement}
            className="bg-primary px-3 py-1 text-onPrimary rounded-lg text-lg font-semibold"
          >
            -
          </button>
        </div>
        <div
          className="flex items-center justify-center p-3 bg-red-200 text-red-600 rounded-lg cursor-pointer"
          onClick={() => handleRemoveItem(element)}
        >
          <Trash className="w-6 h-6" />
        </div>
      </div>
    </div>
  );
};

export default CartItem;
