import menuServices from "../api-service/menu-service";
import Cookies from "js-cookie";
import { getOSInfo, getUserLocation } from "../components/utils/GetInfoScans";

export const createMenuSlice = (set) => {
  return {
    branches: [],
    restaurant: {},
    branch: {},
    menus: [],
    categories: [],
    products: [],
    product: {},
    social: [],
    style: {},
    errorCode: "",
    image_offer: "",
    image_common: "",
    image_new: "",
    popularProducts: [],
    newProducts: [],
    priceOffersProducts: [],
    Menuloading: false,
    categoryLoading: false,
    itemLoading: false,
    branchesLoading: false,
    loading: false,

    fetchBranchesAction: async (payload) => {
      set({
        branchesLoading: true,
      });
      try {
        const res = await menuServices.getBranches(payload);
        set({
          branches: res.data.data.branch,
          social: res.data.data.social,
          restaurant: res.data.data.restaurant,
          style: res.data.data.style,
          branchesLoading: false,
        });
        return res.data.data;
      } catch (error) {
        set({ branchesLoading: false, errorCode: error.status });
      }
    },
    /////////////////////////////////////////////////////////
    fetchMenusAction: async (params) => {
      set({ loading: true });
      try {
        let paramsBranch = {};
        let visit = 0;

        if (!Cookies.get(`branch_${params.branch_id}`)) {
          const oneHourFromNow = new Date(
            new Date().getTime() + 60 * 60 * 1000
          );
          Cookies.set(`branch_${params.branch_id}`, Number(1), {
            expires: oneHourFromNow,
          });
          const osInfo = getOSInfo();
          const location = await getUserLocation();
          visit = 1;
          paramsBranch = {
            ...params,
            visit: visit,
            country: location.country,
            city: location.city,
            system: osInfo,
          };
        } else {
          visit = 0;
          paramsBranch = { ...params, visit: visit };
        }
        const res = await menuServices.getMenu(paramsBranch);
        localStorage.setItem("resturantName", res.data.data.restaurant.name_en);
        set({
          menus: res.data.data.menu,
          restaurant: res.data.data.restaurant,
          branch: res.data.data.branch,
          image_new: res.data.data.branch.image_new,
          image_common: res.data.data.branch.image_common,
          image_offer: res.data.data.branch.image_offer,
          social: res.data.data.social,
          style: res.data.data.style,
        });
        return res.data.data;
      } catch (error) {
        set({
          loading: false,
          errorCode: error.status,
        });
      }
    },

    fetchCategoriesAction: async (params) => {
      set({ Menuloading: true });
      try {
        let visit = 0;
        if (!Cookies.get(`menu_${params.menu_id}`)) {
          const oneHourFromNow = new Date(
            new Date().getTime() + 60 * 60 * 1000
          );
          Cookies.set(`menu_${params.menu_id}`, Number(1), {
            expires: oneHourFromNow,
          });
          visit = 1;
        } else {
          visit = 0;
        }
        const res = await menuServices.getCategory({ ...params, visit: visit });
        set({
          categories: res.data.data,
          Menuloading: false,
        });
        return res.data.data;
      } catch (error) {
        set({
          Menuloading: false,
          errorCode: error.status,
        });
      }
    },

    fetchProductsAction: async (params) => {
      set({ categoryLoading: true });
      try {
        let visit = 0;
        if (!Cookies.get(`category_${params.category_id}`)) {
          const oneHourFromNow = new Date(
            new Date().getTime() + 60 * 60 * 1000
          );
          Cookies.set(`category_${params.category_id}`, Number(1), {
            expires: oneHourFromNow,
          });
          visit = 1;
        } else {
          visit = 0;
        }
        const res = await menuServices.getProduct({ ...params, visit: visit });
        set({
          products: res.data.data,
          categoryLoading: false,
        });
        return res.data.data;
      } catch (error) {
        set({
          categoryLoading: false,
          errorCode: error.status,
        });
      }
    },

    getStyle: async (params) => {
      try {
        const res = await menuServices.getStyle(params);
        set({
          style: res.data.data.style,
        });
        return res.data.data;
      } catch (error) {
        set({
          errorCode: error.status,
        });
      }
    },

    fetchProductByAction: async (productId) => {
      set({ itemLoading: true });
      try {
        let visit = 0;
        if (!Cookies.get(`product_${productId}`)) {
          const oneHourFromNow = new Date(
            new Date().getTime() + 60 * 60 * 1000
          );
          Cookies.set(`product_${productId}`, Number(1), {
            expires: oneHourFromNow,
          });
          visit = 1;
        } else {
          visit = 0;
        }
        const res = await menuServices.getProductById({
          productId: productId,
          visit: visit,
        });
        set({
          product: res.data.data,
          itemLoading: false,
        });
        return res.data.data;
      } catch (error) {
        set({
          itemLoading: false,
          errorCode: error.status,
        });
      }
    },

    selectProductAction: (item) => {
      set({ product: item });
    },
    ////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    ///////////////// products tags///////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    fetchPriceOffersProducts: async (params) => {
      set({ loading: true });
      try {
        const res = await menuServices.getProductPriceOffer(params);

        set((state) => {
          const newMenus = [];
          if (res.data.data.offer.length > 0) {
            newMenus.push({
              id: "offers",
              name_en: "Offers",
              name_ar: " العروض",
              image: res.data.data.image_offer
                ? res.data.data.image_offer
                : res.data.data.offer[0].image,
            });
          }

          if (res.data.data.new.length > 0) {
            newMenus.push({
              id: "new",
              name_en: "New",
              name_ar: " الجديد",
              image: res.data.data.image_new
                ? res.data.data.image_new
                : res.data.data.new[0].image,
            });
          }

          if (res.data.data.common.length > 0) {
            newMenus.push({
              id: "popular",
              name_en: "Popular",
              name_ar: " الأكثر شيوعاً",
              image: res.data.data.image_common
                ? res.data.data.image_common
                : res.data.data.common[0].image,
            });
          }

          return {
            ...state,
            priceOffersProducts: res.data.data.offer,
            newProducts: res.data.data.new,
            popularProducts: res.data.data.common,
            menus: [...newMenus, ...state.menus],
            loading: false,
          };
        });
        return res.data.data;
      } catch (error) {
        set({
          loading: false,
          errorCode: error.response?.status || "Unknown Error",
        });
      }
    },
  };
};

// fetchPopularProducts: async (params) => {
//   set({});
//   try {
//     const res = await menuServices.getProductCommon(params);
//     set((state) => ({
//       popularProducts: res.data.data,
//       menus: [
// {
//   id: "popular",
//   name_en: "Popular",
//   name_ar: " الأكثر شيوعاً",
//   image: res.data.data[0].image,
// },
//         ...state.menus,
//       ],
//       loading: false,
//     }));
//     return res.data.data;
//   } catch (error) {
//     set({
//       loading: false,
//       errorCode: error.status,
//     });
//   }
// },
// fetchNewProducts: async (params) => {
//   set({});
//   try {
//     const res = await menuServices.getProductNew(params);
//     set((state) => ({
//       newProducts: res.data.data,
//       menus: [
//         {
//   id: "new",
//   name_en: "New",
//   name_ar: " الجديد",
//   image: res.data.data[0].image,
// },
//         ...state.menus,
//       ],
//     }));
//     return res.data.data;
//   } catch (error) {
//     set({
//       loading: false,
//       errorCode: error.status,
//     });
//   }
// },
// fetchPriceOffersProducts: async (params) => {
//   set({});
//   try {
//     const res = await menuServices.getProductPriceOffer(params);
//     set((state) => ({
//       priceOffersProducts: res.data.data,
//       menus: [
// {
//   id: "offers",
//   name_en: "Offers",
//   name_ar: " العروض",
//   image: res.data.data[0].image,
// },
//         ...state.menus,
//       ],
//     }));
//     return res.data.data;
//   } catch (error) {
//     set({
//       loading: false,
//       errorCode: error.status,
//     });
//   }
// },
