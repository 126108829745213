import React from "react";
import { SiNamecheap } from "react-icons/si";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import Visibility from "../utils/Visibility";
import { useTranslation } from "react-i18next";
import { indexStore } from "../../store/indexStore";
import { IoPhonePortraitOutline } from "react-icons/io5";
function BranchInfo(props) {
  const { name } = indexStore();

  const { t } = useTranslation();
  const classNameP=`flex gap-2 items-center font-semibold text-lg capitalize`
const classNameIcon=`bg-primary text-onPrimary p-2 rounded-md text-xl font-bold` 
  return (
    <div className="text-onBackground flex flex-col gap-1 pb-2  border-onBackground">
      <p className="text-lg font-bold  "> {t("gloabl.branchInfo")}:</p>
      <div className="flex flex-col gap-2 ">
        <p className={classNameP}>
          <span className={classNameIcon}> <SiNamecheap  /></span>
           {props.branch[name]}
        </p>

        <p className={classNameP}>
        <span className={classNameIcon}>

          <CiLocationOn />
        </span>
          {props.branch[name == "name_en" ? "address_en" : "address_ar"]}
        </p>
        <Visibility visible={props.branch.phone}>
          <p className={classNameP}>
          <span className={classNameIcon}>

            <MdOutlinePhoneInTalk />
          </span>
            <span dir="ltr"> {props.branch.phone}</span>
          </p>
        </Visibility>
        <Visibility visible={props.branch.mobile}>
          <p className={classNameP} >
            <span className={classNameIcon}>

            <IoPhonePortraitOutline />
            </span>
            <span dir="ltr"> {props.branch.mobile}</span>
          </p>
        </Visibility>
      </div>
    </div>
  );
}

export default BranchInfo;
