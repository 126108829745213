import React from "react";
import Visibility from "../utils/Visibility";
import CirclesMenu from "../MenusElements/CirclesMenu";
import LoadingComponent from "./LoadingComponent";

function RenderAndLoadingMenuCilrcles({
  loading,
  menus,
  selectedMenu,
  handleSelectChange,
  children,
}) {
  return (
    <div>
      <Visibility visible={!loading}>
        <CirclesMenu
          data={menus}
          selectedMenu={selectedMenu}
          onClickMenu={handleSelectChange}
        />
        {children}
      </Visibility>
      <Visibility visible={loading}>
        <LoadingComponent
          width={75}
          height={75}
          radius={"50%"}
          count={3}
          gap={10}
        />
      </Visibility>
    </div>
  );
}

export default RenderAndLoadingMenuCilrcles;
