import { useEffect, useRef, useState } from "react";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import axios from "axios";
import * as Ably from "ably";

import {
  AblyProvider,
  ChannelProvider,
  useChannel,
  useConnectionStateListener,
} from "ably/react";
import ChatChanel from "./ChatChanel";
const ChatComponent = () => {
  const [message, setMessage] = useState("");

  const echo = useRef(null);
  useEffect(() => {
    // echo.current = new Echo({
    //   broadcaster: "pusher",
    //   client: new Pusher(
    //     "6jY4lw.wSFrTw:_FCLjMx0u5670bHjTKtGwKEj42beEriZu1190F-OY38",
    //     {
    //       cluster: "mt1",
    //       encrypted: true,
    //       wsHost: "testapi.twhtest.com",
    //       wsPort: 443,
    //       wssPort: 6001,
    //       forceTLS: true, // For SSL connections
    //       disableStats: true, // Optional, to disable Pusher stats
    //       enabledTransports: ["ws", "wss"],
    //     }
    //   ),
    // });
    // echo.current.channel("chat").listen("MessageSent", (e) => {
    //   console.log(e.message);
    // });
  }, []);


  return (
    <ChannelProvider channelName="chat">
      <ChatChanel />
    </ChannelProvider>
  );
};

export default ChatComponent;
