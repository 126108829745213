import React, { useState } from "react";
import { CiGrid41 } from "react-icons/ci";
import { PiRowsThin } from "react-icons/pi";
import Spacer from "../Spacer";
import Visibility from "../utils/Visibility";
import Iterator from "../utils/Iterator";
import ProductRow from "../ProductCards/ProductRow";
import LoadingMenusProduct from "../LoadingComponent/LoadingMenusProduct";
import { indexStore } from "../../store/indexStore";

function ProductsListMenu({ products, handelOnClickProduct, loading }) {
  const { displayWaygrid, changeDisplayWaygrid } = indexStore();
  const changeWayGrid = (way) => {
    changeDisplayWaygrid(way);
  };
  return (
    <div>
      <div className="flex gap-5 py-5 font-bold ">
        <CiGrid41
          className={`cursor-pointer  ${displayWaygrid ? "!text-primary" : ""}`}
          fontSize={30}
          fontWeight={800}
          onClick={() => {
            changeWayGrid(true);
          }}
        />
        <PiRowsThin
          className={`cursor-pointer ${!displayWaygrid ? "text-primary" : " "}`}
          fontSize={30}
          fontWeight={800}
          onClick={() => {
            changeWayGrid(false);
          }}
        />
      </div>
      <Spacer height={30} />
      <Visibility visible={!loading}>
        <div
          className={`flex gap-5  flex-col   ${
            displayWaygrid
              ? "grid grid-cols-1  xs:grid-cols-2  md:grid-cols-3 gap-2 :"
              : ""
          }`}
        >
          <Iterator
            data={products}
            render={(e, i) => (
              <ProductRow
                onClick={() => handelOnClickProduct(e)}
                item={e}
                displayWaygrid={displayWaygrid}
                key={e.id}
              />
            )}
          />
        </div>
      </Visibility>

      <Visibility visible={loading}>
        <LoadingMenusProduct displayWaygrid={displayWaygrid} />
      </Visibility>
    </div>
  );
}

export default ProductsListMenu;
