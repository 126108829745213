import React, { useState } from "react";
import * as Ably from "ably";
import {
  AblyProvider,
  ChannelProvider,
  useChannel,
  useConnectionStateListener,
} from "ably/react";
import axios from "axios";
export default function ChatChanel() {
  const [messages, setMessages] = useState([]);

  useConnectionStateListener("connected", () => {
    // console.log("Connected to Ably!");
  });

  // Create a channel called 'get-started' and subscribe to all messages with the name 'first' using the useChannel hook
  const { channel } = useChannel("chat", "MessageSent", (message) => {
    console.log(message, "Received message");
    setMessages((previousMessages) => [...previousMessages, message]);
  });

  const sendMessage = () => {
    axios.get("https://testapi.twhtest.com/api/send_message", {});
  };
  channel.listeners();
  return (
    // Publish a message with the name 'first' and the contents 'Here is my first message!' when the 'Publish' button is clicked
    <div key={"id"}>
      {/* <button
        onClick={() => {
          channel.publish("first", "Here is my first message!");
        }}
      >
        Publish
      </button> */}
      {/* {messages.map((message) => {
        return <p key={message.id}>{message}</p>;
      })} */}
      <div>
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
}
