import React from "react";
import { Header } from "../header";
import { useParams } from "react-router-dom";

function Orders() {
  const params = useParams();
  return (
    <div className="flex flex-col flex-grow bg-background">
      <title>{params.domain.toUpperCase()}</title>
      <Header />
    </div>
  );
}

export default Orders;
