import React from 'react'
import MenuElement from './MenuElement'
import Iterator from '../utils/Iterator'
import { indexStore } from '../../store/indexStore';

function MenusElemnts(props) {
  const { name, lang } = indexStore();
  return (
    <div
    className={`grid grid-cols-2  md:grid-cols-3 gap-5 `}
  >
    <Iterator
      data={props.data}
      render={(e, i) => (

        <MenuElement
          key={i}
          element={e}
          name={name}
          props={props}

        />
      )}
    />
  </div>
  )
}

export default MenusElemnts